import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "./Core";
import { device } from "../utils";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const ShapeCard = styled(Box)`
  width: 175px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  top: 64%;
  left: 6%;
  transform: scale(0.85);
  @media ${device.sm} {
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
    top: 5%;
  }
`;

const ContentWidget = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  transition: 0.4s;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
  align-items: center;
  display: flex;
  background-color: #f7f7fb;
  border: 1px solid #ececf5;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transform: translateY(-5px);
  }
`;

const ImageContainer = styled.div`
  width: 30px;
  margin-right: 15px;
`;

const ContentCard = ({ logo, children = "" }) => {
  return (
    <ContentWidget>
      <div className="d-flex align-items-center">
        <ImageContainer>
          <PreviewCompatibleImage
            imageInfo={{
              image: logo,
            }}
          />
        </ImageContainer>
        <Title color="dark" variant="card" mb={0}>
          {children}
        </Title>
      </div>
    </ContentWidget>
  );
};

const CaseLeft = ({
  title,
  description,
  clientlogo,
  featuredimage,
  url,
  props,
  button_url,
  imgSize = "big",
  logogroups,
}) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section py={5}>
      <Container>
        <Row className="align-items-top">
          <Col
            md="12"
            lg={imgSize === "small" ? "4" : "6"}
            className="order-lg-2"
          >
            <div className="position-relative pl-lg-5">
              <div
                className="pl-lg-4 text-center text-lg-right"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                  }}
                  style={{ borderRadius: 8 }}
                />
              </div>

              {clientlogo && (
                <a href={button_url} target="_blank" rel="noopener noreferrer">
                  <ShapeCard
                    bg="white"
                    p="18px"
                    borderRadius={8}
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: clientlogo,
                      }}
                    />
                  </ShapeCard>
                </a>
              )}
            </div>
          </Col>
          <Col
            lg={imgSize === "small" ? "8" : "6"}
            className="order-lg-1 mt-5 mt-lg-0"
          >
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <Box>
                {title && <Title>{title}</Title>}
                <Text mb={4}>{description}</Text>

                {logogroups && (
                  <Row>
                    {logogroups.map((logogroup, i) => (
                      <>
                        <Col
                          sm="12"
                          className="mt-4 mb-2"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-once="true"
                        >
                          <Text>
                            <b>{logogroup.title}</b>
                          </Text>
                        </Col>
                        {logogroup.logos.map((logo, i) => (
                          <Col
                            sm="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                          >
                            <a
                              href={logo.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ContentCard logo={logo.logo}>
                                {logo.name}
                              </ContentCard>
                            </a>
                          </Col>
                        ))}
                      </>
                    ))}
                  </Row>
                )}

                {url && (
                  <Link to={url}>
                    <Button mt={3}>{props.cta}</Button>
                  </Link>
                )}
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseLeft;
